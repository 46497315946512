<template>
  <mcr-static-glossary-link :label="label" :glossary-content="glossaryContent"></mcr-static-glossary-link>
</template>

<script>
import McrStaticGlossaryLink from '@common/elements/glossary/mcrStaticGlossaryLink';

export default {
  props: {
    id: String,
    label: String,
  },
  data() {
    return {
      glossaryContent: '',
    };
  },
  mounted() {
    let element = document.getElementById(this.id);
    this.glossaryContent = element ? element.innerHTML : '';
  },
  components: {McrStaticGlossaryLink},
};
</script>

<style scoped></style>
